import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Link from 'components/ui/link';
import withUsabilityTestingTemplate from 'hoc/content-cluster';
import arrow from 'img/ui/arrow_white.svg';
import Img from 'gatsby-image';
import Banner from 'components/usability-testing/banner';

const WhatIsUsabilityTesting = () => {
  const { devices, revenue, player } = useStaticQuery(graphql`
    query {
      devices: file(
        relativePath: { eq: "usability-testing/what-is-usability-testing/devices.png" }
      ) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      revenue: file(
        relativePath: { eq: "usability-testing/what-is-usability-testing/revenue.png" }
      ) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      player: file(relativePath: { eq: "usability-testing/what-is-usability-testing/player.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <>
      <h2>What is usability testing?</h2>
      <p>
        Usability testing is a form of examining how users interact with a product. Users act as
        test subjects when they interact with it and complete tasks. Testing can be done on your
        website’s navigation, on an app’s user interface, and even on physical products.
      </p>
      <Img
        fluid={devices.childImageSharp.fluid}
        alt="Usability testing"
        title="Test your product"
      />
      <p>Depending on the design, users will interact differently with various products.</p>
      <p>
        When we say “usability” this also refers to the methods that can be used to improve
        ease-of-use in the design process.
      </p>
      <p>
        After all, you want it to be as simple and easy as possible for users to interact and use
        your product.
      </p>
      <p>
        Testing, from early in the product development to product release and beyond, can provide
        valuable insights and data to improve your current product, when possible, or to inspire
        future product iterations.
      </p>
      <h3>Usability definition – what do we mean by ‘usability’?</h3>
      <p>
        If you want to drill down the usability definition even further, these five quality
        components by{' '}
        <Link
          href="https://www.nngroup.com/articles/usability-101-introduction-to-usability/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          Jakob Nielsen
        </Link>{' '}
        of Nielsen Norman Group can help:
      </p>
      <ol>
        <li>
          <strong>Learnability</strong>. How easy is it for users to complete basic tasks the first
          time using a product or interface?
        </li>
        <li>
          <strong>Efficiency</strong>. After your users have used your interface and design, how
          efficient can they be when performing tasks?
        </li>
        <li>
          <strong>Memorability</strong>. Memorability is a lot like efficiency in that users have
          already used your design multiple times. But if they haven’t used your design for weeks,
          months or even years, can they return to high efficiency quickly?
        </li>
        <li>
          <strong>Errors or bugs</strong>. When users make errors, and they always do, can they
          recover from them? Also, how many errors can be considered “severe?”
        </li>
        <li>
          <strong>Satisfaction</strong>. Did users find your design pleasant to use?
        </li>
      </ol>
      <p>
        Now that you know what usability testing is and how usability works, it’s time to learn why
        usability is so important in design.
      </p>
      <h2>The benefits of usability testing</h2>
      <p>
        There are a lot of usability tests that you can perform, including remote usability testing,
        explorative testing, assessment testing, and comparative testing.
      </p>
      <p>But what is usability testing good for and what benefits does it offer you?</p>
      <h3>Improved user experience</h3>
      <p>
        User experience can enhance satisfaction, reduce customer service issues, and increase the
        longevity of product usage.
      </p>
      <p>And if you’re thinking big businesses don’t test usability, you’re wrong.</p>
      <p>
        Apple tweaked their Mac’s UI to improve user experience, and the result was{' '}
        <Link
          href="https://uxplanet.org/why-is-it-important-to-do-usability-testing-5080a5640df3"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          90% fewer support calls
        </Link>
        . Impressive, isn’t it?
      </p>
      <h3>Increased conversion</h3>
      <p>
        Conversions are actions that you want users to take. Perhaps you want the user to purchase a
        pro version of your app – proper usability testing can increase the number of such actions
      </p>
      <p>
        If you own a website, you may use conversions with your email marketing to improve the
        number of subscribers to your list.
      </p>
      <h3>Generating more revenue</h3>
      <p>
        Revenue keeps businesses afloat. Single usability issues can lead to massive revenue loss.{' '}
        <Link
          href="https://thenextscoop.com/usability-testing-benefits/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          A bank in Australia
        </Link>{' '}
        found one key issue in their app’s design that, when fixed, led to a potential 600% increase
        in revenue generation for mobile loan applications.
      </p>
      <Img
        fluid={revenue.childImageSharp.fluid}
        alt="Statistics"
        title="Our customers didn't get the experience they deserve"
      />
      <p>
        Source:{' '}
        <Link
          href="https://thenextscoop.com/usability-testing-benefits/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          NextApp
        </Link>
      </p>
      <h3>Showing brand commitment to great UX</h3>
      <p>
        Happy users lead to referrals, long-term income, and success. Brands that continue usability
        testing show that they’re committed to<em> </em>their user experience. A company can gain
        the trust of their users by showing their dedication to their customer’s experience.
      </p>
      <h3>Learning about the target user’s behavior and preferences</h3>
      <p>
        Big data is everywhere (your smartphone is likely collecting your data right now), and you
        can collect data during testing. When you conduct any type of usability testing, you’re
        gathering data on the user’s behavior and preferences.
      </p>
      <Banner title="Improve usability testing with LiveSession" />
      <h2>What are the types of usability testing?</h2>
      <p>
        We’ve talked a lot about{' '}
        <Link href="/usability-testing/" target="_blank" rel="noopener noreferrer">
          usability testing
        </Link>
        , but it’s time to find out what the right testing for your business may be. The key types
        of testing are:
      </p>
      <h3>1. In-person vs remote usability testing</h3>
      <p>
        In-person and remote usability testing allow the creation of products that are robust,
        user-friendly and ready for market. Your product type and what you’re testing will dictate
        which form is best suited to your product.
      </p>
      <p>You wouldn’t evaluate an app’s interface the same way you would test a vacuum – right?</p>
      <p>That’s why a mix of testing methods are chosen when refining your product.</p>
      <h4>
        <strong>In-person usability testing methods:</strong>
      </h4>
      <ol>
        <li>
          <strong>Lab testing</strong>: A lab setting allows for a controlled environment where the
          person tested can be assessed based on the target market characteristics. Lab testing
          helps identify problems based on specific parameters, allows for quantitative data
          collection, confirms user challenges, and evaluates new prototypes. Testing can be
          performed in-house or in a third-party facility.
        </li>
        <li>
          <strong>Guerilla testing</strong>: A powerful method, guerilla testing involves going out
          and testing with as many random people as possible. If you had a powerful stain remover,
          you could go to cafes or stand on the street corner guerilla testing the product. You’ll
          gain valuable data on how different, random users interact with your product, the
          difficulties they face and how best to improve it.
        </li>
        <li>
          <strong>Observational usability testing</strong>: A form of stealth evaluation,
          observational testing allows you to understand user behavior when you’re not there. The
          test participant is set up in a quiet location and allowed to use your product. You may
          use screen recording or video recording to observe their behavior. It’s important to only
          intervene when a participant is truly stuck and facing a problem.
        </li>
        <li>
          <strong>Eye-tracking</strong>: Users’ eye positions can tell you a lot about your
          interface. Eye-tracking allows you to measure a person’s eye movement on a web page or
          app. You&apos;ll be able to see what parts of the interface get the most focus and which
          parts are overlooked.
        </li>
      </ol>
      <h4>Remote usability testing methods:</h4>
      <p>
        Remote usability alleviates the stress and anxiety users face when tests are conducted
        in-person. A lot of remote methods can be used to gather data, but the most common forms
        are:
      </p>
      <ol>
        <li>
          <strong>Phone interview</strong>: User interviews are an integral part of UX research,
          where participants are asked questions by researchers. These one-on-one sessions provide
          valuable feedback on a product and allow both the tester and researcher to ask questions.
        </li>
        <li>
          <strong>Digital card sorting</strong>:{' '}
          <Link
            href="https://www.justinmind.com/blog/card-sorting/"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Card sorting
          </Link>{' '}
          is a form of remote interface testing that’s simple and effective. When trying to improve
          or perfect your user experience, digital card sorting works well. Users are given digital
          cards that are organized in a way that makes sense for them. Through sorting you’re able
          to determine how users would adapt the information architecture and what form of
          organization works best for the user.
        </li>
        <li>
          <strong>Session recordings</strong>: Screen recording{' '}
          <Link href="/solutions/#usability-testing">tools like LiveSession</Link> allow you to test
          ideas remotely and watch users as they traverse your interface. Recordings let you gain
          valuable insights and collect qualitative data. You’ll be able to find errors, hotspots,
          or areas where users struggle with your interface.
        </li>
      </ol>
      <Img
        fluid={player.childImageSharp.fluid}
        alt="Session recordings - LiveSession"
        title="LiveSession provides a recording tool"
      />
      <p>
        Source: <Link href="/features/">LiveSession</Link>
      </p>
      <h3>2. Exploratory testing vs comparative testing</h3>
      <p>
        Explorative and comparative testing methods provide insights into how users utilize your
        product naturally and how it compares to the competition.
      </p>
      <p>Let&apos;s take a deeper look into these forms of usability testing methods:</p>
      <h4>Exploratory testing</h4>
      <p>
        Exploratory testing removes influence on the evaluator, allowing for an on-the-fly
        evaluation to see how users interact with your product. Users may be asked to write down
        notes before the test, but the main goal is to explore your product freely.
      </p>
      <p>
        Agile models rely heavily on explorative testing because each individual tester is given the
        freedom and responsibility of testing your product or interface naturally.
      </p>
      <h4>Comparative testing</h4>
      <p>
        Comparative tests are used to compare your product against two or more products. When
        performed properly, the test allows you to better understand your product strengths and
        weaknesses versus your competitors.
      </p>
      <p>
        You can further use comparative testing to generate new ideas, features, and functions for
        your product.
      </p>
      <h3>3. Assessment Testing</h3>
      <p>
        When you want to conduct a general usability test for your product,{' '}
        <Link
          href="
https://mentormate.com/blog/four-distinct-approaches-to-successful-usability-testing/#:~:text=Assessment%20Test%3A&text=Assessment%20%E2%80%94%20also%20known%20as%20a,and%20how%20they%20impact%20usability"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          an assessment test
        </Link>{' '}
        is what you’ll want to perform. These tests often come after exploratory testing to
        determine how certain aspects of design impact usability.
      </p>
      <p>
        During an assessment, the user is asked to complete specific tasks and may even speak aloud
        when performing them to give insights into their actions.
      </p>
      <p>
        You’ll want to run an assessment test in the early and midway phase of product development.
        Tweak your product after testing to improve your design to cater to your audience.
      </p>
      <h2>Summary</h2>
      <p>
        Now that you know what usability testing is, how remote usability testing, in-person,
        comparative, and assessment testing work, it’s time to collect the data your company needs
        to improve your product! By collecting user data, you’ll be able to modify the user
        experience, enhance results, and improve your end-users’ satisfaction. This, in turn, will
        allow you to create a successful product that adds value and is enjoyable to its users.All
        that’s left to say? Good luck on your usability testing endeavours!
      </p>
      <div className="next-chapter">
        <Link href="/usability-testing/usability-testing-questions/">
          <div>
            <p>Chapter 2</p>
            <p>24 Usability Testing Questions You Need To Ask Your Audience</p>
          </div>
          <img src={arrow} alt="arrow" title="white arrow" />
        </Link>
      </div>
    </>
  );
};

export default withUsabilityTestingTemplate(WhatIsUsabilityTesting);
